import React from 'react'
import { GatsbySeo } from 'gatsby-plugin-next-seo'
import { graphql } from 'gatsby'
import Layout from '../components/UI/layout/Layout'
import { GridContent } from '../components/UI/layout/grid-content'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { BgImage } from 'gbimage-bridge'
import MaxiMenu from '../components/UI/navigation/maxi-menu'
import { Link } from 'gatsby'

const Corporate = ({ data }) => {
  const venue = data.allFile.edges[0].node.childMarkdownRemark.frontmatter
  return (
    <Layout>
      <GatsbySeo title="Corporate Venue" />

      <GridContent id="introduction" mode="white" layout="--2-column">
        <div className="text-content-margin">
          <h2>{venue.introduction.title}</h2>
          <h3>{venue.introduction.sub_heading}</h3>
          <p>{venue.introduction.body}</p>
        </div>

        <BgImage
          image={getImage(venue.introduction.image)}
          className="image-background float-right"
          style={{
            backgroundSize: 'cover',
            backgroundPosition: 'left center',
          }}
        />
      </GridContent>
      <GridContent
        id="corporate-page-1"
        layout="--3-column content-margin-bottom content-margin-top"
        mode="paradise-garden-club"
      >
        <GatsbyImage
          image={getImage(venue.page_1.image_left)}
          alt="page 1 image"
        />
        <div className="text-content-margin">
          {venue.page_1.centre.quote && (
            <h5 className="quote">{venue.page_1.centre.quote}</h5>
          )}
          <p className="markdown">{venue.page_1.centre.body}</p>
        </div>
        <GatsbyImage
          image={getImage(venue.page_1.image_right)}
          alt="page 1 image"
        />
      </GridContent>

      <GridContent
        id="venue-page-2"
        mode="white-mode"
        layout="--2-column content-margin-bottom content-margin-top"
      >
        <GatsbyImage image={getImage(venue.page_2.image)} alt="page 2 image" />
        <p className="text-content-margin">{venue.page_2.body}</p>
      </GridContent>
      <GatsbyImage
        id="full-bleed-image"
        image={getImage(venue.full_image)}
        alt="background"
        style={{ maxHeight: '60vh', width: '100%' }}
      />
      <GridContent
        id="venue-page-3"
        mode="white-mode"
        layout="--2-column content-margin-top content-margin-bottom"
      >
        <div className="space-evenly">
          <p className="markdown">{venue.page_3.body}</p>
          <Link
            className="align-right mock-button"
            to={venue.page_3.button.link}
          >
            {venue.page_3.button.label}
          </Link>
        </div>

        <GatsbyImage
          image={getImage(venue.page_3.image_right)}
          alt="background"
        />
      </GridContent>
      <MaxiMenu />
    </Layout>
  )
}

export default Corporate

export const query = graphql`
  query {
    allFile(filter: { name: { eq: "corporate" } }) {
      edges {
        node {
          childMarkdownRemark {
            frontmatter {
              introduction {
                body
                sub_heading
                title
                image {
                  childImageSharp {
                    gatsbyImageData(
                      width: 820
                      placeholder: BLURRED
                      formats: [AUTO, WEBP, AVIF]
                    )
                  }
                }
              }

              full_image {
                childImageSharp {
                  gatsbyImageData(
                    width: 1200
                    placeholder: BLURRED
                    formats: [AUTO, WEBP, AVIF]
                  )
                }
              }
              page_1 {
                centre {
                  quote
                  body
                }
                image_left {
                  childImageSharp {
                    gatsbyImageData(
                      width: 820
                      placeholder: BLURRED
                      formats: [AUTO, WEBP, AVIF]
                    )
                  }
                }
                image_right {
                  childImageSharp {
                    gatsbyImageData(
                      width: 820
                      placeholder: BLURRED
                      formats: [AUTO, WEBP, AVIF]
                    )
                  }
                }
              }
              page_2 {
                body
                image {
                  childImageSharp {
                    gatsbyImageData(
                      width: 820
                      placeholder: BLURRED
                      formats: [AUTO, WEBP, AVIF]
                    )
                  }
                }
              }
              page_3 {
                body
                button {
                  label
                  link
                }
                image_right {
                  childImageSharp {
                    gatsbyImageData(
                      width: 820
                      placeholder: BLURRED
                      formats: [AUTO, WEBP, AVIF]
                    )
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`
